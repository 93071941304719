






















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import dmas from '@/data/dmas'
import states from '@/data/state_options'
import Widget from '@/components/Widget/Widget.vue'
import Api from '@/models/Api'
import MapChart from './MapChart/MapChart.vue'

@Component({
  components: {
    Widget,
    MapChart,
  },
})
export default class Map extends ViewModel {
  @Prop({
    default: () => ({}),
  })
  public data!: any;

  @Prop({
    default: () => ({}),
  })
  public settings!: any;

  @Prop({
    default: () => ({}),
  })
  public callback_data!: any;

  @Prop({ default: false })
  public busy!: boolean;

  @Prop({ default: false })
  public hide_details!: boolean;

  @Prop({ default: 'dma' })
  public mode!: string;

  private rating_data: any = [];

  private MIN_IMPRESSIONS = 50;

  private local_mode: string = 'dma';

  private local_loading: boolean = false;

  private dma_data: any = [];

  private state_data: any = [];

  private top_dma: any = [];

  private top_state: any = [];

  private total_dma_impressions: number = 0;

  private total_state_impressions: number = 0;

  public get dmas() {
    return dmas
  }

  public get states() {
    return states
  }

  public get total_impressions() {
    return this.local_mode === 'dma'
      ? this.total_dma_impressions
      : this.total_state_impressions
  }

  public get mapData() {
    return this.local_mode === 'dma' ? this.dma_data : this.state_data
  }

  public get topRegionData() {
    return this.local_mode === 'dma' ? this.top_dma : this.top_state
  }

  @Watch('data')
  public onDataChange() {
    if (this.data) {
      if (!this.user.isSystem && this.local_mode === 'dma' && this.data.dmas) {
        const api = new Api()
        api
          .get('dma-ratings', {
            dmas: this.data.dmas.map((d: any) => d.name),
          })
          .then(response => {
            this.rating_data = response.data
            this.updateData(this.data.dmas ?? [], this.data.states ?? [])
          })
      } else {
        this.updateData(this.data.dmas ?? [], this.data.states ?? [])
      }
    }
  }

  @Watch('local_mode')
  public onModeChange() {
    this.$parent.$emit('modeChange', {
      card: 'map_chart',
      mode: this.local_mode,
    })
  }

  @Watch('callback_data')
  public onCallback() {
    if (this.callback_data) {
      this.updateData(
        this.callback_data.cb === 'dma' ? this.callback_data.data.dmas : null,
        this.callback_data.cb === 'state'
          ? this.callback_data.data.states
          : null,
      )
      this.local_mode = this.callback_data.cb
      this.local_loading = false
    }
  }

  public toggleMode() {
    if (this.local_mode === 'dma') {
      if (this.state_data.length > 0) {
        this.local_mode = 'state'
      } else {
        this.local_loading = true
        this.$parent.$emit('requestData', {
          cb: 'state',
          request: {
            cards: ['geo_distribution'],
            map: 'state',
          },
        })
      }
    } else if (this.dma_data.length > 0) {
      this.local_mode = 'dma'
    } else {
      this.local_loading = true
      this.$parent.$emit('requestData', {
        cb: 'dma',
        request: {
          cards: ['geo_distribution'],
          map: 'dma',
        },
      })
    }
  }

  public updateData(dma_payload: any = null, state_payload: any = null) {
    let result: any = []

    if (!this.data) return
    if (dma_payload !== null) {
      this.total_dma_impressions = dma_payload.reduce(
        (a: number, b: any) => a + b.impressions,
        0,
      )
      const tmp: any = {}
      dma_payload.forEach((d: any) => {
        const code = d.name
        const dma = this.dmas.find(d => d.id == code)
        const rating = this.rating_data.find((d: any) => d.id == code)
        let name = 'N/A'

        if (dma) {
          name = dma.name
        }
        const { impressions } = d
        const { reach } = d
        tmp[code] = {
          code,
          name,
          impressions,
          reach,
          rating,
        }
      })

      for (const key in tmp) {
        result.push(tmp[key])
      }

      this.dma_data = result

      this.top_dma = this.dma_data
        .sort((a: any, b: any) => (a.code ? (a.impressions > b.impressions ? -1 : 1) : 1))
        .filter((r: any) => r.impressions > this.MIN_IMPRESSIONS)
        .slice(0, 5)
    }

    if (state_payload !== null) {
      this.total_state_impressions = state_payload.reduce(
        (a: number, b: any) => a + b.impressions,
        0,
      )
      result = []

      const tmp: any = {}
      state_payload.forEach((d: any) => {
        const code = d.name
        const state = states.find((d: any) => d.value == code)
        const name = state?.name
        let { impressions } = d

        if (tmp[code]) {
          impressions += tmp[code].impressions
        }

        tmp[code] = {
          code,
          name,
          impressions,
        }
      })

      for (const key in tmp) {
        result.push(tmp[key])
      }

      this.state_data = result

      this.top_state = this.state_data
        .sort((a: any, b: any) => (a.code ? (a.impressions > b.impressions ? -1 : 1) : 1))
        .filter((r: any) => r.impressions > this.MIN_IMPRESSIONS && r.code !== null && r.code != 'N/A')
        .slice(0, 5)
    }
  }

  public dmaName(code: any) {
    const dma = this.dmas.find((r: any) => r.id == code)
    if (dma) return `${dma.name} (${code})`
    return code
  }

  public created() {
    this.local_mode = this.mode
    if (this.data) this.updateData(this.data.dmas, this.data.states)
  }
}
