









































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import MediaOceanInvoice from '@/models/MediaOceanInvoice'
import SelectOption from '@/models/interface/SelectOption'
import { Md5 } from 'ts-md5/dist/md5'
import Api from '@/models/Api'

@Component({
  components: {
    SelectPicker,
  },
})
export default class MediaOceanPropertyPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean;

  @Prop({ default: false })
  public allow_empty!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public multiple!: boolean;

  @Prop({ default: null })
  public owner_id!: string | null;

  @Prop({ default: null })
  public value!: string | null | string[];

  @Prop({
    default: 'MediaOceanInvoice',
  })
  public label!: string;

  @Prop({ default: false })
  public showType!: boolean;

  @Prop({ default: false })
  public create_panel!: boolean;

  @Prop({ default: false })
  public taggable!: boolean;

  @Prop({ default: null })
  public field!: string | null;

  @Prop({ default: () => [] })
  public filters!: any;

  @Prop({ default: null })
  public start!: string | null;

  @Prop({ default: null })
  public end!: string | null;

  @Prop({ default: null })
  public not_type!: string | null;

  private local_value: string | null | string[] = null;

  public uuid: string = '';

  public update: boolean = true;

  public busy: boolean = false;

  public options: SelectOption[] = [];

  public ready: boolean = false;

  public local_hash: any = '';

  public get hash() {
    return Md5.hashStr(
      JSON.stringify({
        field: this.field,
        filters: this.filters,
        start: this.start,
        end: this.end,
      }),
    )
  }

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      this.updateOptions().then(() => {
        this.ready = true
      })
    } else {
      this.local_value = this.value
      this.ready = true
    }
  }

  public open() {
    this.updateOptions()
  }

  public updateOptions() {
    if (this.hash === this.local_hash) {
      return new Promise(resolve => {
        resolve('ok')
      })
    }
    this.busy = true
    const api = new Api()
    return api
      .post('media_ocean_invoice/properties/search/option', {
        field: this.field,
        filters: this.filters,
        start: this.start,
        end: this.end,
      })
      .then(response => {
        this.options = response.data.result.options.map(
          (o: any) => new SelectOption(o.name, o.value),
        )
        this.updateValues()
        this.local_hash = this.hash
        this.busy = false
      })
  }

  public updateValues() {
    if (this.value && Array.isArray(this.value)) {
      this.local_value = this.value.filter(v => this.options.some(o => o.value == v))
    }
  }

  @Watch('local_value')
  public onChange() {
    this.$emit('input', this.local_value)
  }

  @Watch('hash')
  public onHashChange() {
    this.updateOptions()
  }
}
