





































































import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import Overview from './Cards/Overview.vue'
import Distribution from './Cards/Distribution.vue'
import PieChart from './Cards/PieChart.vue'
import BarChart from './Cards/BarChart.vue'
import Table from './Cards/Table.vue'
import Map from './Cards/Map.vue'
import OrdersView from './Cards/OrdersView/OrdersView.vue'

@Component({
  components: {
    Widget,
    Overview,
    Distribution,
    PieChart,
    BarChart,
    Table,
    Map,
    OrdersView,
  },
})
export default class Card extends ViewModel {
  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: false })
  public busy!: boolean;

  @Prop({ default: false })
  public hide_details!: boolean;

  @Prop({ default: '' })
  public type!: string;

  @Prop({ default: '' })
  public sub_type!: string;

  @Prop({ default: '' })
  public mode!: string;

  @Prop({ default: null })
  public settings!: any;

  @Prop({ default: null })
  public data!: any;

  @Prop({ default: null })
  public callback_data!: any;

  @Prop({ default: null })
  public filters!: any;

  @Prop({ default: null })
  public columns!: any;

  @Prop({
    default: () => ({
      lg: 4,
      md: 6,
      sm: 12,
      xs: 12,
    }),
  })
  public size!: any;

  public showWidget(card: string) {
    const no_widget = ['bar_chart', 'map_chart', 'order_view']

    return !no_widget.includes(card)
  }
}
