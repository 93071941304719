



































import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {},
})
export default class Distribution extends ViewModel {
  @Prop({ default: null })
  public data!: any

  @Prop({ default: false })
  public loading!: boolean

  @Prop({ default: null })
  public sub_type!: string

  public get total() {
    if (!this.data) return 0
    return this.data.reduce((carry: number, item: any) => item.value + carry, 0)
  }
}
