




























































import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {
    SelectPicker,
  },
})
export default class DownloadModal extends ViewModel {
  @Prop({ default: false })
  public show!: boolean;

  public local_show: boolean = false;

  public show_items: boolean = false;

  public show_creatives: boolean = false;

  public map_mode: string = 'dma';

  public mode: string = 'insights';

  public download_options: object[] = [
    { name: 'Export Insights PDF', value: 'insights' },
    { name: 'Export IOs', value: 'orders' },
  ];

  public map_options: object[] = [
    { name: 'DMA', value: 'dma' },
    { name: 'State', value: 'state' },
  ];

  @Watch('show')
  public onChangeShow() {
    this.local_show = this.show
  }

  @Watch('local_show')
  public onChangeLocalShow() {
    this.$emit('update:show', this.local_show)
  }

  public confirmDownload() {
    this.$emit('confirm', {
      show_items: this.show_items,
      show_creatives: this.show_creatives,
      map: this.map_mode,
      mode: this.mode,
    })
    this.local_show = false
  }

  public created() {
    this.local_show = this.show
  }
}
