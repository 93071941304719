






























































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import Filter from '@/models/Filter'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import ViewModel from '@/models/ViewModel'
import Inventory from '@/models/Inventory'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import PublisherPicker from '@/components/PublisherPicker/PublisherPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import MediaOceanPropertyPicker from '@/components/MediaOceanPropertyPicker/MediaOceanPropertyPicker.vue'
import SelectOption from '@/models/interface/SelectOption'

@Component({
  components: {
    SelectPicker,
    DatePicker,
    CompanyPicker,
    PublisherPicker,
    UserPicker,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
    MediaOceanPropertyPicker,
  },
})
export default class QueryFilter extends ViewModel {
  @Prop({ default: new Filter() })
  public value!: Filter

  @Prop({ default: false })
  public date!: boolean

  @Prop({ default: false })
  public show!: boolean

  public local_show: boolean = false

  public estimate_codes: SelectOption[] = []

  public product_names: SelectOption[] = []

  public advertiser_names: SelectOption[] = []

  private order_type_options: SelectOption[] = [
    new SelectOption('Cash & Trade', 'All'),
    new SelectOption('Cash', 'Cash'),
    new SelectOption('Trade', 'Trade'),
  ]

  public get inventory_options() {
    return Inventory.module.type_options
  }

  public get publisher_options() {
    return Inventory.module.publisher_options
  }

  public get device_options() {
    return Inventory.module.device_name_options
  }

  public addCodeOption(option: string) {
    this.estimate_codes.push(new SelectOption(option, option))
    this.value.agency_estimate_code.push(option)
  }

  public addProductOption(option: string) {
    this.product_names.push(new SelectOption(option, option))
    this.value.product_name.push(option)
  }

  public addAdvertiserOption(option: string) {
    option = option.toUpperCase()
    this.advertiser_names.push(new SelectOption(option, option))
    this.value.agency_advertiser_code.push(option)
  }

  @Watch('show')
  public onChangeShow() {
    this.local_show = this.show
  }

  @Watch('local_show')
  public onChangeLocalShow() {
    this.$emit('update:show', this.local_show)
  }

  public apply() {
    if (this.local_show) this.local_show = false

    this.$emit('apply')
  }

  public created() {
    this.local_show = this.show
  }

  public reset() {
    this.value.reset()
  }
}
