/** @format */

export default {
  details: {
    loading: true,
    size: {
      lg: 4,
      md: 6,
      sm: 12,
      xs: 12,
    },
    data: null as any,
    settings: null as any,
  },
  cards: [
    {
      key: 'impressions',
      type: 'overview',
      loading: true,
      size: {
        lg: 4,
        md: 6,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
    },
    {
      key: 'day_distribution',
      sub_type: 'day_distribution',
      type: 'distribution',
      title: 'Days Distribution',
      loading: true,
      size: {
        lg: 4,
        md: 6,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
    },
    {
      key: 'devices',
      sub_type: 'device_pie',
      type: 'pie_chart',
      title: 'Devices',
      loading: true,
      size: {
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
    },
    /* Disable Widget
    {
      key: 'hour_distribution',
      sub_type: 'hour_distribution',
      type: 'distribution',
      title: 'Hour Distribution',
      loading: true,
      size: {
        lg: 4,
        md: 6,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings:null as any,
    }, */
    {
      key: 'orders',
      type: 'order_view',
      sub_type: 'order_view',
      title: 'Campaigns',
      loading: true,
      mode: 'default',
      table: true,
      size: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
      callback_data: null as any,
    },
    {
      key: 'map',
      type: 'map_chart',
      sub_type: 'geo_distribution',
      mode: 'dma',
      title: 'Map',
      loading: true,
      size: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
      callback_data: null as any,
    },
    {
      key: 'publishers',
      sub_type: 'media_placements',
      type: 'table',
      title: 'Media Placements',
      loading: true,
      table: true,
      size: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
      columns: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center',
          type: 'abbreviate',
          sortable: true,
        },
        {
          key: 'completion_rate',
          label: 'Completion Rate',
          sortable: true,
          class: 'text-center',
          type: 'percentage_key',
          percentage_keys: ['completed', 'impressions'],
        },
        {
          key: 'share',
          label: 'Share',
          sortable: true,
          class: 'text-center',
          type: 'total_percentage',
          total_key: 'impressions',
        },
      ],
    },
    {
      key: 'overview_chart',
      sub_type: 'daily_impressions',
      type: 'bar_chart',
      title: 'Impression & Completion Rate',
      loading: true,
      size: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      data: null as any,
      settings: null as any,
    },
  ],
}
