



































import Widget from '@/components/Widget/Widget.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import apexchart from 'vue-apexcharts'
import ViewModel from '@/models/ViewModel'
import moment from 'moment'

@Component({
  components: {
    Widget,
    apexchart,
  },
})
export default class BarChart extends ViewModel {
  @Prop({
    default: () => ({
      dates: [],
      labels: [],
      completion_rate: [],
      impressions: [],
    }),
  })
  public payload: any

  public local_payload: any

  @Prop({ default: '' })
  public title!: string

  @Prop({ default: false })
  public busy!: boolean

  public loaded: boolean = false

  public mode = 'day'

  public updateData() {
    this.local_payload = {
      dates: this.payload.map((o: any) => o.date),
      labels: this.payload.map((o: any) => o.name),
      impressions: this.payload.map((o: any) => o.impressions),
      start: this.payload.map((o: any) => o.start),
      completed: this.payload.map((o: any) => o.completed),
      completion_rate: this.payload.map((o: any) => o.completion_rate.toFixed(2)),
    }
  }

  public buildData(payload: any) {
    const data: any = {}
    // build data
    const ret = {
      dates: [],
      labels: [],
      completion_rate: [],
      impressions: [],
    }

    if (this.mode === 'day') return payload

    for (const k in payload.labels) {
      let idx: any = null
      const date = moment(payload.dates[k])
      if (this.mode === 'week') {
        idx = date.weeks()
      } else if (this.mode === 'month') {
        idx = `${date.month()}, ${date.year()}`
      }

      let base = {
        min: null as any,
        max: null as any,
        impressions: 0,
        start: 0,
        completed: 0,
        completion_rate: '',
        name: idx,
      }
      if (idx in data) {
        base = data[idx]
      }
      if (base.min === null || base.min.isAfter(date)) base.min = date
      if (base.max === null || base.max.isBefore(date)) base.max = date
      base.impressions += payload.impressions[k]
      base.start += payload.start[k]
      base.completed += payload.completed[k]
      let vcr = base.completed / base.start
      if (vcr >= 1) {
        // vcr = base.completed / base.impressions
        vcr = 1
      }
      /*
      if (vcr >= 1) {
        vcr = 0.9503
      } */
      base.completion_rate = (vcr * 100).toFixed(2)
      if (this.mode === 'week') {
        base.name = `${base.min.format('Do MMM, YYYY')} - ${base.max.format('Do MMM, YYYY')}`
      } else base.name = base.min.format('MMM, YYYY')

      data[idx] = base
    }

    for (const key in data) {
      // @ts-ignore
      ret.labels.push(data[key].name)
      // @ts-ignore
      ret.completion_rate.push(data[key].completion_rate)
      // @ts-ignore
      ret.impressions.push(data[key].impressions)
    }

    return ret
  }

  public chartOptions: any = {
    chart: {
      id: 'impressions_completion_rate',
      toolbar: false,
      height: 500,
      animations: {
        enabled: !this.user.isSystem,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: true,
      formatter(value: number) {
        return `${Math.ceil(value)}%`
      },
      enabledOnSeries: [0],
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: 'Completion Rate',
        },
        labels: {
          formatter(value: number) {
            return `${value.toFixed(2)}%`
          },
        },
      },
      {
        seriesName: 'Impressions',
        title: {
          text: 'Impressions',
        },
        labels: {
          formatter(value: number) {
            return Math.ceil(value)
          },
        },
      },
    ],
  }

  public series: any = []

  @Watch('payload')
  public onDataChange() {
    this.updateData()
    this.reloadChart()
  }

  @Watch('mode')
  public onModeChange() {
    this.reloadChart()
  }

  public mounted() {
    this.reloadChart()
  }

  public weekOfMonth(input = moment()) {
    const firstDayOfMonth = input.clone().startOf('month')
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week')

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days')

    return Math.ceil((input.date() + offset) / 7)
  }

  public reloadChart() {
    this.loaded = false

    if (!this.payload) return

    const data = this.buildData(this.local_payload)

    if (!data) return

    this.chartOptions = {
      ...this.chartOptions,
      ...{
        xaxis: {
          categories: data.labels,
        },
      },
    }

    this.series = []
    this.series.push({
      name: 'Completion Rate',
      type: 'line',
      data: data.completion_rate,
    })
    this.series.push({
      name: 'Impressions',
      type: 'bar',
      data: data.impressions,
    })

    this.loaded = true
  }
}
