































import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import Widget from '@/components/Widget/Widget.vue'
import ItemData from './components/ItemData.vue'

@Component({
  components: {
    IconAction,
    ItemData,
    Widget,
  },
})
export default class OrdersView extends ViewModel {
  @Prop({ default: () => [] })
  public data!: any;

  @Prop({
    default: () => ({}),
  })
  public callback_data!: any;

  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: false })
  public busy!: boolean;

  public advertisers: string[] = [];

  @Prop({ default: 'default' })
  public mode!: string;

  @Prop({ default: () => false })
  public showItems!: boolean;

  @Prop({ default: () => false })
  public showCreatives!: boolean;

  public get showDownload() {
    return false && !this.busy && this.user.isSuperAdmin
  }

  public getCallback(advertiser_id: string) {
    if (
      this.callback_data
      && this.callback_data.cb.advertiser_id === advertiser_id
    ) {
      return this.callback_data
    }
    return null
  }
}
