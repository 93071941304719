










































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {},
})
export default class Table extends ViewModel {
  @Prop({ default: null })
  public data!: any

  @Prop({ default: false })
  public columns!: any

  public totals: any = {}

  @Watch('data')
  public updateTotal() {
    const core_keys = ['total', 'total_percentage']
    const keys = [] as string[]

    this.totals = {}
    this.columns.forEach((c: any) => {
      if (core_keys.includes(c.type) && !keys.includes(c.total_key)) keys.push(c.total_key)
    })

    if (keys.length > 0) {
      this.data.forEach((d: any) => {
        keys.forEach(key => {
          if (this.totals[key]) {
            this.totals[key] += d[key]
          } else {
            this.totals[key] = d[key]
          }
        })
      })
    }
  }
}
